import Navbar from '../Navbar'
import Footer from '../Footer'
import Maintenance from '../Maintenance';
import { useEffect, useState } from 'react';
import Loader from '../Loader';
import { fetchUserToken } from '../../helper/api-methods';
import appboy from 'appboy-web-sdk';
function PublicLayout(props){
    const [ loading, setLoading ] = useState(true);
    
    useEffect(()=>{
        const guestToken = localStorage.getItem("guestToken");
        if(guestToken && guestToken.length){
            setLoading(false);
        }
        else{
            fetchUserToken("web/guestUserLogin").then((response)=>{
                localStorage.setItem("guestToken",response.data.Data.Token.Access_Token);
                localStorage.setItem("guestCustomerGuid",response.data.Data.UserInfo.CustomerGuid);
                appboy.changeUser(response.data.Data.UserInfo.CustomerGuid);
                setTimeout(() => { setLoading(false); }, 500);
            })
        }
    },[])

    return (
        props.maintenance ? <Maintenance/> :
        loading ? <Loader/> :
        <>
            { props.hasHeader ? <Navbar/> : <></> }
            <div className={props.hasHeader ? "" : "no-breadcrumb"}>
                {props.children}
            </div>
            { props.hasFooter ? <Footer/> : <></> }
        </>
    )
}
export default PublicLayout; 