import React, { useRef } from "react";
import { useState, useEffect } from "react";
import AddressCard from "./address-card";
import '../../assets/css/--component-account-address.css'
import { getUserAddress, postAddress, removeAddress, updateAddress, getRegions, getCities } from "../../helper/backend-methods";
import { analyticsPageEvent } from "../../components/Analytics";
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { useCallback } from "react";
import { toast } from "react-toastify";
import T, { _T } from "../../locale/translations-main";
function Address(props) {
    const [userAddress, setUserAddress] = useState([])
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true)
    const [titleSelection, setTitleSelection] = useState("Home")
    const [title, setTitle] = useState("Home")
    const [address, setAddress] = useState("")
    const [addresses, setAddresses] = useState([])
    const [street, setStreet] = useState("")
    const [zipcode, setZipcode] = useState("")
    const [province, setProvince] = useState(0);
    const [city, setCity] = useState(0)
    const [apiLoading, setApiLoading] = useState(true);
    const [errors, setErrors] = useState({ title: "", address: "", street: "", zipcode: "", city: "", province: "" })
    const [configurePageTitle, configureBreadcrumb] = props.methods
    const [createAddress, setCreateAddress] = useState(false)
    const [language, setLanguage] = useState("en")
    const [update, setUpdate] = useState(0)
    const [boundryError, setBoundryError] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [map, setMap] = useState(null);
    const [autoComplete, setAutoComplete] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 21.478773843809332, lng: 39.18643173979255 })
    const [mapMarker, setMapMarker] = useState({ lat: 21.478773843809332, lng: 39.18643173979255 })
    const [mapLoaded, setMapLoaded] = useState(false);
    const searchRef = useRef("");
    // edit mode states
    const [editTitle, setEditTitle] = useState("")
    const [editAddress, setEditAddress] = useState("")
    const [editStreet, setEditStreet] = useState("")
    const [editZipcode, setEditZipcode] = useState("")
    const [editRegionId, setEditRegionId] = useState(0);
    const [editCityId, setEditCityId] = useState(0)
    const [editMapMarker, setEditMapMarker] = useState({ lat: 0.00, lng: 0.00 })
    const [isEditable, setIsEditable] = useState(false)
    const [editId, setEditId] = useState(false)
    const [editIsDefault, setEditIsDefault] = useState(false)
    // edit mode states end
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Account", link: "" },
        { title: "Address", link: "" },
    ]
    // Google map Methods
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
        libraries: ['places']
    })
    // useEffect(()=>{
    //     fetchAddresses()
    // },[update])
    useEffect(() => {
        if (mapLoaded) {
            autoComplete.addListener('place_changed', onPlaceSelect)
        }
    }, [mapLoaded])
    const onUnmount = useCallback(function callback(map) {
        setMap(null)
        setAutoComplete(null)
        setMapLoaded(false)
    }, [])
    const onLoad = (map) => {
        setAutoComplete(new window.google.maps.places.Autocomplete(searchRef.current))
        const bounds = new window.google.maps.LatLngBounds(mapCenter);
        map.fitBounds(bounds);
        setMap(map)
        setMapLoaded(true)
    }
    const onPlaceSelect = () => {
        const place = autoComplete.getPlace()
        if (place.geometry.viewport || place.geometry.location) {
            // console.log('place selected: ', place)
            setStreet(place.formatted_address)
            setEditStreet(place.formatted_address)
            setMapMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            setEditMapMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            setMapCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            map.panTo({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            map.setZoom(15)
        }
    }
    // const fetchAddresses = () => {
    //     getUserAddress().then(({data})=>{
    //         if(data.status){
    //             setAddresses(data.result.addressList)
    //             setSelectedArea(data.result.areaId)
    //             setApiLoading(false)
    //         }
    //         else{
    //             toast("Unable to fetch addresses")
    //         }
    //     }).catch((e)=>{
    //         toast(e.message)
    //     })
    // }

    // useEffect(() => {
    //     if (props.consent.page) {
    //         analyticsPageEvent("Account Addresses");
    //     }
    // }, [props.consent.page])

    useEffect(() => {
        configurePageTitle("Address")
        configureBreadcrumb(breadcrumbItems)
        getUserAddress().then(({ data }) => {
            if (data.Status) {
                setUserAddress(data.Data ? data.Data : [])
            }
            getRegions().then(({ data }) => {
                setCity(0)
                setRegions(data.Data)
                // setProvince(data.Data[0].RegionId)
                getCities({ regionId: data.Data[0].RegionId }).then(({ data }) => {
                    // setCity(data.Data[0].CityId)
                    setCities(data.Data)
                    setApiLoading(false)
                    setLoading(false)
                }).catch((e) => {
                    setLoading(false)
                    setApiLoading(false)
                })
            }).catch((e) => {
                setLoading(false)
                setApiLoading(false)
            })
        }).catch((e) => {
            console.log(e);
        })
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            setLanguage(lang)
        }
    }, []);

    useEffect(() => {
        if (update) {
            setLoading(true)
            getUserAddress().then(({ data }) => {
                if (data.Status) {
                    setUserAddress(data.Data)
                }
                setLoading(false)
            }).catch((e) => {
                setLoading(false)
                console.log(e);
            })
        }
    }, [update]);

    const fetchCities = (value) => {
        setApiLoading(true)
        setCity(0)
        isEditable ? setEditRegionId(value) : setProvince(value)
        getCities({ regionId: value }).then(({ data }) => {
            // setCity(data.Data[0].CityId)
            setCities(data.Data)
            setApiLoading(false)
        }).catch((e) => {
            setApiLoading(false)
            console.log(e);
        })
    }

    const getAddressFromCoordinates = (position) => {
        setBoundryError(false)
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: position }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setStreet(results[0].formatted_address)
                setEditStreet(results[0].formatted_address)
            }
        });
    };

    // Google map methods ends

    const validateSubmit = (e) => {
        e.preventDefault();
        var zipcodeRE = new RegExp("^\\d{5}(-{1}\\d{4})?$");
        const errors = { title: "", address: "", street: "", zipcode: "", city: "", province: "" };
        let flag = false;
        if (!title || title.replaceAll(/\s/g, '').length < 1) {
            errors.title = <T>Address title is required</T>;
            flag = true
        }
        if (!address || address.replaceAll(/\s/g, '').length < 1) {
            errors.address = <T>Please provide a valid Address</T>;
            flag = true
        }
        if (!zipcode || zipcode.replaceAll(/\s/g, '').length < 1 || !zipcodeRE.test(zipcode)) {
            errors.zipcode = <T>Please provide a valid ZipCode</T>;
            flag = true
        }
        if (!province || parseInt(province) === 0) {
            errors.province = <T>Please select event State/Province</T>;
            flag = true;
        }
        if (!city || parseInt(city) === 0) {
            errors.city = <T>Please select event City/Town</T>;
            flag = true;
        }
        if (!street || street.replaceAll(/\s/g, '').length < 1) {
            errors.street = <T>Please provide a valid street address</T>;
            flag = true
        }

        setErrors(errors);
        if (!flag) {
            postAddress({ Title: title, HouseNo: address, StreetAddress: street, ZipCode: zipcode, cityId: city, regionId: province, Coordinates: { Longitude: mapMarker.lng, Latitude: mapMarker.lat }, isDefault: false }).then(({ data }) => {
                setCreateAddress(false)
                resetForm()
                setUpdate(update + 1)
            }).catch((e) => {
                console.log(e);
                setCreateAddress(false)
                resetForm()
                setUpdate(update + 1)
            })
        }
    }

    const validateSubmitEdit = (e) => {
        e.preventDefault();
        var zipcodeRE = new RegExp("^\\d{5}(-{1}\\d{4})?$");
        const errors = { title: "", address: "", street: "", zipcode: "", city: "", province: "" };
        let flag = false;
        if (!title || title.replaceAll(/\s/g, '').length < 1) {
            errors.title = <T>Address title is required</T>;
            flag = true
        }
        if (!editAddress || editAddress.replaceAll(/\s/g, '').length < 1) {
            errors.address = <T>Please provide a valid Address</T>;
            flag = true
        }
        if (!editZipcode || editZipcode.replaceAll(/\s/g, '').length < 1 || !zipcodeRE.test(editZipcode)) {
            errors.zipcode = <T>Please provide a valid ZipCode</T>;
            flag = true
        }
        if (!editRegionId || parseInt(editRegionId) === 0) {
            errors.province = <T>Please select event State/Province</T>;
            flag = true;
        }
        if (!editCityId || parseInt(editCityId) === 0) {
            errors.city = <T>Please select event City/Town</T>;
            flag = true;
        }
        if (!editStreet || editStreet.replaceAll(/\s/g, '').length < 1) {
            errors.street = <T>Please provide a valid street address</T>;
            flag = true
        }
        setErrors(errors);
        if (!flag) {
            updateAddress({ id: editId, Title: title, HouseNo: editAddress, StreetAddress: editStreet, ZipCode: editZipcode, CityId: editCityId, regionId: editRegionId, Coordinates: { Longitude: editMapMarker.lng, Latitude: editMapMarker.lat }, IsDefault: editIsDefault }, "?addressId=" + editId).then(({ data }) => {
                addressDataUpdate()
                setIsEditable(false)
                setCreateAddress(false)
                setTitleSelection("Home")
            }).catch((e) => {
                console.log(e);
                setIsEditable(false)
                setCreateAddress(false)
                setTitleSelection("Home")
            })
        }
    }

    const resetForm = () => {
        setTitleSelection("Home")
        setTitle("Home")
        setAddress("")
        setStreet("")
        setZipcode("")
        setCity(0)
        setProvince(0)
        setErrors({ title: "", address: "", street: "", zipcode: "", city: "", province: "" })
    }

    const addressDataUpdate = () => {
        setUpdate(update + 1)
    }

    const makeDefault = (address) => {
        updateAddress({ id: address.Id, Title: address.Title, HouseNo: address.HouseNo, StreetAddress: address.StreetAddress, ZipCode: address.ZipCode, cityId: address.CityId, regionId: address.RegionId, isDefault: true, Coordinates: address.Coordinates }).then(({ data }) => {
            setUpdate(update + 1)
        }).catch((e) => {
            console.log(e);
        })
    }

    const deleteAddress = (address) => {
        removeAddress("?addressId=" + address.Id).then((response) => {
            setUpdate(update + 1)
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleItemClicked = (item) => {
        setTitle(item.Title)
        if (item.Title !== "Home" && item.Title !== "Work" && item.Title !== "Partner") {
            setTitleSelection("new")
        } else {
            setTitleSelection(item.Title)
        }
        setEditAddress(item.HouseNo)
        setEditStreet(item.StreetAddress)
        setEditZipcode(item.ZipCode)
        setEditRegionId(item.RegionId)
        setEditCityId(item.CityId)
        setEditId(item.Id)
        setEditIsDefault(item.IsDefault)
        setEditMapMarker({ lat: item.Coordinates.Latitude, lng: item.Coordinates.Longitude })
        setMapCenter({ lat: item.Coordinates.Latitude, lng: item.Coordinates.Longitude })
        setCreateAddress(true)
        // console.log(item.Coordinates.Latitude)
    }
    const cancelUpdate = () => {
        setIsEditable(false)
        setMapMarker({ lat: 21.478773843809332, lng: 39.18643173979255 })
        setCreateAddress(false)
    }
    return (
        <section id="address-section">
            <div className="address-card">
                <div className='profile-body-header mb-4'>
                    <h1 className='fs-24 text-gray-500 fw-500 mt-0 mb-0'><T>My Address</T></h1>
                    <p className='fs-16 text-gray-400 fw-400 mt-0 mb-0'><T>My Delivery Address</T></p>
                </div>
                {
                    isEditable || createAddress ?
                        <></> :
                        <div className="d-flex justify-between flex-wrap">
                            {
                                loading ?
                                    <span className="btn btn-alternative w-100 mb-4"><T>Fetching address data. Please wait....</T></span> :
                                    userAddress.length ?
                                        userAddress.map((item, index) => {
                                            return <AddressCard key={index} methodSetIsEditable={setIsEditable} isEditable={isEditable} methodItemClicked={handleItemClicked} cityList={cities} regionList={regions} item={item} methodDelete={deleteAddress} methodDefault={makeDefault} methodUpdate={addressDataUpdate} />
                                        }) : <div className='btn btn-alternative w-100 mb-5'><i className="ri-error-warning-line mr-2 fs-22"></i><T>You currently have no addresses configured in your account.</T></div>
                            }
                        </div>
                }
                {
                    createAddress ?
                        <form onSubmit={isEditable ? validateSubmitEdit : validateSubmit}>
                            <div className={createAddress ? "address-card-wrapper w-100 pb-7" : "address-card-wrapper"} >
                                <div className="d-flex justify-between">
                                    <div className="new-address-details">
                                        <h3 className="fs-18 fw-500 text-gray-500 mt-0">{isEditable ? <T>Update Address</T> : <T>Add New Address</T>}</h3>
                                    </div>
                                    <div className="save-cancel">
                                        <button type="button" className="btn btn-alternative mr-3" onClick={() => { isEditable ? cancelUpdate() : setCreateAddress(false); resetForm() }}><T>Cancel</T></button>
                                        <button disabled={apiLoading} className="btn btn-primary" type="submit">{apiLoading ? "Please Wait" : isEditable ? <T>Update</T>: <T>Save</T> }</button>
                                    </div>
                                </div>
                                <div className="d-flex justify-between flex-wrap">
                                    <div className="address-form-content mb-3 w-100 title-selector">
                                        <input id="newPreDefinedTitle01" type={"radio"} value="Home" onChange={(e) => { if (e.target.checked) { setTitleSelection("Home"); setTitle("Home") } }} checked={titleSelection === "Home"} name="newPreDefinedTitle01" className="d-none" />
                                        <label className="cursor-pointer mr-2" htmlFor="newPreDefinedTitle01">
                                            <i className="ri-home-4-line"></i>
                                        </label>
                                        <input id="newPreDefinedTitle02" type={"radio"} value="Work" onChange={(e) => { if (e.target.checked) { setTitleSelection("Work"); setTitle("Work") } }} checked={titleSelection === "Work"} name="newPreDefinedTitle02" className="d-none" />
                                        <label className="cursor-pointer mr-2" htmlFor="newPreDefinedTitle02">
                                            <i className="ri-briefcase-3-line"></i>
                                        </label>
                                        <input id="newPreDefinedTitle03" type={"radio"} value="Partner" onChange={(e) => { if (e.target.checked) { setTitleSelection("Partner"); setTitle("Partner") } }} checked={titleSelection === "Partner"} name="newPreDefinedTitle03" className="d-none" />
                                        <label className="cursor-pointer mr-2" htmlFor="newPreDefinedTitle03">
                                            <i className="ri-heart-3-line"></i>
                                        </label>
                                        <input id="newPreDefinedTitle04" type={"radio"} value="new" onChange={(e) => { if (e.target.checked) { setTitleSelection("new"); isEditable ? setTitle("") : setTitle("") } }} checked={titleSelection === "new"} name="newPreDefinedTitle04" className="d-none" />
                                        <label className="cursor-pointer mr-2" htmlFor="newPreDefinedTitle04">
                                            <i className="ri-add-line"></i>
                                        </label>
                                    </div>
                                    <div className="address-form-content w-100 mt-3 mb-3">
                                        <div className="form-content mb-2">
                                            <input placeholder='Search Here' ref={searchRef} />
                                        </div>
                                        {isLoaded ?
                                            <GoogleMap clickableIcons={false} onClick={(e) => { isEditable ? setEditMapMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() }) : setMapMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() }); getAddressFromCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() }) }} center={mapCenter} zoom={12} onLoad={onLoad} options={{ zoomControl: false, streetViewControl: false, mapTypeControl: false, maxZoom: 16 }} mapContainerClassName={"map-container"} onUnmount={onUnmount}>
                                                <Marker position={isEditable ? editMapMarker : mapMarker} />
                                            </GoogleMap>
                                            : <></>}

                                        {/* <p>{mapMarker.lat}, {mapMarker.lng} ||| {editMapMarker.lat}, {editMapMarker.lng} </p> */}
                                    </div>
                                    <div className={titleSelection === "new" || titleSelection === "" ? "address-form-content mb-2" : "address-form-content mb-2 d-none"}>
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="newTitle"><T>Title</T><span className="text-primary">*</span></label>
                                        <input type="text" name="newTitle" id="newTitle" onChange={(e) => { setTitle(e.target.value.slice(0, 20)) }} value={title} className="w-100" />
                                        {errors.title ? <span className="text-primary fs-12 fw-400">{errors.title}</span> : null}
                                    </div>
                                    <div className="address-form-content mb-2">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="newAddress"><T>House No/Flat No</T><span className="text-primary">*</span></label>
                                        <input type="text" name="newAddress" id="newAddress" onChange={(e) => { isEditable ? setEditAddress(e.target.value.slice(0, 50)) : setAddress(e.target.value.slice(0, 50)) }} value={isEditable ? editAddress : address} placeholder={_T("House No/Flat No")} />
                                        {errors.address ? <span className="text-primary fs-12 fw-400">{errors.address}</span> : null}
                                    </div>
                                    <div className="address-form-content mb-2">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="newStreet"><T>Street Address</T><span className="text-primary">*</span></label>
                                        <input type="text" name="newStreet" id="newStreet" onChange={(e) => { isEditable ? setEditStreet(e.target.value.slice(0, 100)) : setStreet(e.target.value.slice(0, 100)) }} value={isEditable ? editStreet : street} placeholder={_T("Street Address")} />
                                        {errors.street ? <span className="text-primary fs-12 fw-400">{errors.street}</span> : null}
                                    </div>
                                    <div className="address-form-content mb-2">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="newProvince"><T>Region</T><span className="text-primary">*</span></label>
                                        <select name="newProvince" id="newProvince" onChange={(e) => { fetchCities(e.target.value) }} value={isEditable ? editRegionId : province} className="w-100">
                                            <option value={0}><T>Select Region</T></option>
                                            {
                                                regions.map((item, index) => {
                                                    return <option key={index} value={item.RegionId}>{item.RegionName}</option>
                                                })
                                            }
                                        </select>
                                        {errors.province ? <span className="text-primary fs-12 fw-400">{errors.province}</span> : null}
                                    </div>
                                    <div className="address-form-content mb-2">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="newCity"><T>City/Town</T><span className="text-primary">*</span></label>
                                        <select name="newCity" id="newCity" onChange={(e) => { isEditable ? setEditCityId(e.target.value) : setCity(e.target.value) }} value={isEditable ? editCityId : city} className="w-100">
                                            <option value={0}><T>Select City/Town</T></option>
                                            {
                                                cities.map((item, index) => {
                                                    return <option key={index} value={item.CityId}>{item.CityName}</option>
                                                })
                                            }
                                        </select>
                                        {errors.city ? <span className="text-primary fs-12 fw-400">{errors.city}</span> : null}
                                    </div>
                                    <div className="address-form-content mb-2">
                                        <label className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block" htmlFor="newZipcode"><T>Zip Code</T><span className="text-primary">*</span></label>
                                        <input type="text" name="newZipcode" id="newZipcode" onChange={(e) => { isEditable ? setEditZipcode(e.target.value.slice(0, 10).replace(/[^0-9-]+/g, '')) : setZipcode(e.target.value.slice(0, 10).replace(/[^0-9-]+/g, '')) }} value={isEditable ? editZipcode : zipcode} placeholder={_T("Zip Code")} />
                                        {errors.zipcode ? <span className="text-primary fs-12 fw-400">{errors.zipcode}</span> : null}
                                    </div>
                                    <span className="address-form-content"></span>
                                </div>
                            </div>
                        </form> : <div className="add-new-address">
                            <button onClick={() => { setCreateAddress(true) }} className="btn btn-outline-primary"> <i className="ri-map-pin-line fs-20 mr-1"></i><T>Add New Address</T></button>
                        </div>
                }
            </div>
        </section>
    )
}

export default Address;