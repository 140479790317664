const translations = {
    "Only": {
      "en": "Only",
      "ar": "فقط"
    },
    "PCs left": {
      "en": "PCs left",
      "ar": "قطع تركت"
    },
    "Reviews": {
      "en": "Reviews",
      "ar": "المراجعات"
    },
    "Remove from Wishlist": {
      "en": "Remove from Wishlist",
      "ar": "إزالة من قائمة الأمنيات"
    },
    "Add to Wishlist": {
      "en": "Add to Wishlist",
      "ar": "أضف إلى قائمة الامنيات"
    },
    "You may choose to add this to your occasion list and let invited guests buy this for you.": {
      "en": "You may choose to add this to your occasion list and let invited guests buy this for you.",
      "ar": "يمكنك اختيار إضافة هذا إلى قائمة المناسبات الخاصة بك والسماح للضيوف المدعوين بشراء هذا لك."
    },
    "Category:": {
      "en": "Category:",
      "ar": "فئة:"
    },
    "Brand:": {
      "en": "Brand:",
      "ar": "ماركة:"
    },
    "Age:": {
      "en": "Age:",
      "ar": "عمر:"
    },
    "Description": {
      "en": "Description",
      "ar": "وصف"
    },
    "Additional Info": {
      "en": "Additional Info",
      "ar": "معلومات إضافية"
    },
    "Pending Approval": {
      "en": "Pending Approval",
      "ar": "الموافقات المعلقة"
    },
    "This product has no reviews": {
      "en": "This product has no reviews",
      "ar": "لا يوجد تقييمات لهذا المنتج"
    },
    "This product has no description": {
      "en": "This product has no description",
      "ar": "هذا المنتج ليس له وصف"
    },
    "Related Products": {
      "en": "Related Products",
      "ar": "منتجات ذات صله"
    },
    "Products that are similar to": {
      "en": "Products that are similar to",
      "ar": "المنتجات المشابهة"
    },
    "Frequently Bought Together": {
      "en": "Frequently Bought Together",
      "ar": "غالبا يتم شراؤها معا"
    },
    "Products that were often purchased together with": {
      "en": "Products that were often purchased together with",
      "ar": "المنتجات التي تم شراؤها غالبًا مع"
    },
    "Add to Cart": {
      "en": "Add to Cart",
      "ar": "أضف إلى السلة"
    },
    "Go to Cart": {
      "en": "Go to Cart",
      "ar": "اذهب إلى سلة التسوق"
    },
    "Add to List": {
      "en": "Add to List",
      "ar": "أضف إلى القائمة"
    },
    "Select Option": {
      "en": "Select Option",
      "ar": "حدد خيار"
    },
    "Product Brand:": {
      "en": "Product Brand:",
      "ar": "العلامة التجارية للمنتج:"
    },
    "You already have this product added to your occasion.": {
      "en": "You already have this product added to your occasion.",
      "ar": "لقد أضفت هذا المنتج بالفعل إلى مناسبتك."
    },
    "Buy this for someone else": {
      "en": "Buy this for someone else",
      "ar": "شراء هذا لشخص آخر"
    },
    "Clear": {
      "en": "Clear",
      "ar": "صافي"
    },
    "You already have items in your cart and you are about to add a product that belongs to an occasion. This will clear all your current cart items.": {
      "en": "You already have items in your cart and you are about to add a product that belongs to an occasion. This will clear all your current cart items.",
      "ar": "لديك منتجات في عربة التسوق الخاصة بك وأنت على وشك إضافة منتج ينتمي إلى مناسبة ما. سيؤدي هذا إلى مسح جميع منتجات عربة التسوق الحالية."
    },
    "You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.": {
      "en": "You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.",
      "ar": "لديك منتجات مرتبطة بمناسبة في عربة التسوق الخاصة بك وأنت على وشك إضافة منتج لا ينتمي إلى أي مناسبة. سيؤدي هذا إلى مسح جميع منتجات عربة التسوق الحالية."
    },
    "You already have items associated with an occasion in your cart and you are about to add a product that belongs to some other occasion. This will clear all your current cart items.": {
      "en": "You already have items associated with an occasion in your cart and you are about to add a product that belongs to some other occasion. This will clear all your current cart items.",
      "ar": "لديك منتجات مرتبطة بمناسبة ما في عربة التسوق الخاصة بك وأنت على وشك إضافة منتج ينتمي إلى مناسبة أخرى. سيؤدي هذا إلى مسح جميع منتجات عربة التسوق الحالية."
    },
    "This item can be returned": {
      "en": "This item can be returned",
      "ar": "يمكن إرجاع هذا المنتج"
    },
    "This item cannot be returned": {
      "en": "This item cannot be returned",
      "ar": "لا يمكن إرجاع هذا المنتج"
    },
    "Off": {
      "en": "Off",
      "ar": "خصم"
    },
    "Buy Now!": {
      "en": "Buy Now!",
      "ar": "اشتري الآن!"
    }
  }
  export default translations