import { useState, useEffect, useRef } from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import "../../assets/css/--component-product-list.css"
import coverImage from "../../assets/images/product-list-cover.png"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ProductCard from "./product-card";
import { getProducts, getFilters } from "../../helper/backend-methods";
import Sidebar from "./products-sidebar";
import { useSearchParams } from "react-router-dom";
import { analyticsPageEvent, analyticsTrackEvent } from "../../components/Analytics";
import T from "../../locale/translations-main";

function Products(props) {
    const [ loadMoreCount, setLoadMoreCount] = useState(0);
    const loader = useRef(false);
    const [ search, setSearch] = useSearchParams();
    const [ sortBy, setSortBy] = useState(0);
    const [ loading, setLoading] = useState(true);
    const [ sidebarLoading, setSidebarLoading] = useState(true);
    const [ btnLoading, setBtnLoading] = useState(false);
    const [ isArabic, setIsArabic ] = useState(false)
    const [ refresh, setRefresh ] = useState(0)
    const [ showSidebar, setShowSidebar ] = useState(false)
    const [ filters, setFilters ] = useState(null)
    const [ special , setSpecial ] = useState(null)
    const [ activeFilters , setActiveFilters ] = useState(null)
    const [ products, setProducts ] = useState([])
    const [ page, setPage ] = useState(1)
    const [ limit, setLimit ] = useState(12)
    const [ totalProducts, setTotalProducts ] = useState(0)

    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Products", link: "" },
    ];

    const loadMore = () => {
        setBtnLoading(true)
        getProducts({...activeFilters, ...(special && {special: special}), limit: limit, offset: page * limit, sortBy: sortBy}).then(({data})=>{
            if(data.Status){
                setTotalProducts(data.Data.ProductTotal)
                setProducts([...products,...data.Data.Products])
                setPage(page+1)
            }
            loader.current = false;
            setBtnLoading(false)
        }).catch((e)=>{
            console.log(e)
        })
    }

    const updateFilters = (options) => {
        const filterOptions = {
            PriceMin: options.priceMinSet,
            PriceMax: options.priceMaxSet,
            ...(special && {special: special}),
            ...(options.keywordFilterOptions.length && {Keyword: options.keywordFilterOptions}),
            ...(options.genderFilterOptions.length && {Gender: options.genderFilterOptions}),
            ...(options.brandFilterOptions.length && {Brand: options.brandFilterOptions}),
            ...(options.categoryFilterOptions.length && {Category: options.categoryFilterOptions}),
            ...(options.ageFilterOptions.length && {Age: options.ageFilterOptions}),
            Rating: options.Rating,
            limit: limit,
            offset: 0,
            sortBy: sortBy
        }
        setActiveFilters(filterOptions)
        setRefresh(refresh+1)
    }

    useEffect(() => {
        if(refresh > 0){
            getProducts({...activeFilters, ...(special && {special: special}), sortBy: sortBy, limit: limit, offset: 0}).then(({data})=>{
                if(data.Status){
                    setTotalProducts(data.Data.ProductTotal)
                    setProducts(data.Data.Products)
                    setPage(1)
                }
            }).catch((e)=>{
                console.log(e)
            })
        }
    }, [refresh]);

    useEffect(() => {
        setLoading(true)
        if(props.consent.page){
          analyticsPageEvent("Product Listing");
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        let items = {};
        if(search){
            if(search.get('gender')){
                items = {
                    "Gender": [search.get('gender')]
                }
                setActiveFilters(items)
                analyticsTrackEvent("Category Viewed",{ screen_name: "category_product_listing", category_id: search.get('gender') },props.consent.tracking)
            }
            if(search.get('category')){
                items = {
                    "Category": [search.get('category')]
                }
                setActiveFilters(items)
                analyticsTrackEvent("Category Viewed",{ screen_name: "category_product_listing", category_id: search.get('gender') },props.consent.tracking)
            }
            if(search.get('brand')){
                items = {
                    "Brand": [search.get('brand')]
                }
                setActiveFilters(items)
                analyticsTrackEvent("Brand Viewed",{ screen_name: "brand_product_listing", brand_id: search.get('brand') },props.consent.tracking)
            }
            if(search.get('tag')){ 
                items = {
                    "special": search.get('tag')
                }
                setSpecial(search.get('tag'))
            }
            if(search.get('keyword')){ 
                items = {
                    "Keyword": search.get('keyword')
                }
                setActiveFilters(items)
                analyticsTrackEvent("Product Search",{ screen_name: "search_product_listing", keyword: search.get('keyword') },props.consent.tracking)
            }
        }
        // const lang = localStorage.getItem("LANG")
        // if(lang && lang.length){
        //     if(lang === "ar"){
        //         setIsArabic(true)
        //         setLanguage("ar")
        //         set'Content'(translations.productList.ar)
        //     }
        // }
        getProducts({ ...items, limit: limit, offset: 0, sortBy: sortBy}).then(({data})=>{
            if(data.Status){
                setTotalProducts(data.Data.ProductTotal)
                setProducts(data.Data.Products)
                setLoading(false)
                getFilters().then(({data})=>{
                    if(data.Status){
                        let allFilters = data.Data;
                        if(items.Gender){
                            allFilters = { ...allFilters, AllGender: allFilters.AllGender.map((i)=> i.Id == items.Gender[0] ? { ...i, Active: true } : { ...i, Active: false } ) }
                        }
                        if(items.Category){
                            allFilters = { ...allFilters, AllCategories: allFilters.AllCategories.map((i)=> i.Id == items.Category[0] ? { ...i, Active: true } : { ...i, Active: false } ) }
                        }
                        if(items.Brand){
                            allFilters = { ...allFilters, AllBrands: allFilters.AllBrands.map((i)=> i.Id == items.Brand[0] ? { ...i, Active: true } : { ...i, Active: false } ) }
                        }
                        if(items.Keyword){
                            allFilters = { ...allFilters, Keyword: items.Keyword }
                        }
                        setFilters(allFilters)
                        setSidebarLoading(false)
                        initializeListener();
                    }
                }).catch((e)=>{
                    console.log(e)
                })
            }
        }).catch((e)=>{
            console.log(e)
        })
    }, [search,props.consent]);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar)
    }

    useEffect(()=>{
        if(loadMoreCount){
            loadMore();
        }
    },[loadMoreCount])

    const initializeListener = () => {
        window.addEventListener('scroll', function() {
            var targetElement = document.getElementById('loadMoreProducts'); // Replace with your element's ID
            if(targetElement){
                var elementPosition = targetElement.getBoundingClientRect();
                if (elementPosition.top >= 0 && elementPosition.bottom <= window.innerHeight) {
                    if(!loader.current){
                        loader.current = true;
                        setLoadMoreCount((prev)=>prev+1);
                    }
                }
            }
        });
    }


    return (
        <>
            <BreadCrumbs pageTile="Store" breadcrumbItems={breadcrumbItems} />
            <section id="productList">
                { loading ?
                <div className="wrapper d-flex justify-between">
                    <aside className="product-sidebar">
                        <Skeleton height="800px" style={{width: "100%"}} />
                    </aside>
                    <div className="products-container">
                        <div className="banner-image-container">
                            <Skeleton height="230px" style={{width: "100%", borderRadius: "5px"}} />
                        </div>
                        <div className="d-flex justify-between mb-5">
                            <Skeleton height="42px" style={{width: "150px"}} />
                            <Skeleton height="42px" style={{width: "150px"}} />
                        </div>
                        <div className="products-wrapper d-flex flex-wrap justify-between">
                            {
                                [...Array(4)].map((x,i)=>(
                                    <div className="product-card p-0 border-none dummy" key={i}>
                                        <Skeleton height="460px" style={{width: "100%"}} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                :
                <div className={ showSidebar ? "wrapper d-flex justify-between sidebar-open" : "wrapper d-flex justify-between"}>
                    {
                        sidebarLoading ?
                        <aside className="product-sidebar">
                            <Skeleton height="800px" style={{width: "100%"}} />
                        </aside> : 
                        <Sidebar FilterOptions={filters} update={updateFilters} toggle={toggleSidebar}/> 
                    }
                    <div className="products-container">
                        <div className="banner-image-container">
                            <img src={coverImage} className="banner-image" alt=""/>
                            <div className="banner-image-text">
                                <h4><T>ClickToys</T></h4>
                                <h6><T>A world filled with</T> <span><T>Happiness</T></span> <T>and</T> <span><T>Fun</T></span></h6>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-between">
                            <div className="products-sort">
                                <label htmlFor="productSort"><T>Sort By:</T></label>
                                <select className="productSort" id="productSort" name="productSort" defaultValue={sortBy} onChange={(e)=>{ setSortBy(parseInt(e.target.value)); setRefresh(refresh+1) }}>
                                    <option value="0"><T>Most Recent</T></option>
                                    <option value="1"><T>Most Popular</T></option>
                                    <option value="2"><T>Lowest Price</T></option>
                                    <option value="3"><T>Highest Price</T></option>
                                </select>
                            </div>
                            <div className="products-filter-handle">
                                <button className="btn-filter" type="buton" onClick={()=>{ toggleSidebar() }} ><i className="ri-equalizer-line mr-3"></i><T>Filters</T></button>
                            </div>
                        </div>
                            {
                                products.length ?
                                <div className={"products-wrapper"}>
                                    {
                                        products.map((item,index)=>{
                                            return <ProductCard key={index} item={item} pageTitle="Product Listing" permissions={props.consent}/>
                                        })
                                    }
                                </div> : 
                                <p className="btn btn-alternative d-flex align-items-center m-0 w-100"><i className="ri-information-line fs-22 mr-2"></i><T>No Products Found. Please try a different filter.</T></p>
                            }
                            {
                                totalProducts > products.length ?
                                <div className="products-pagination d-flex align-items-center justify-center mt-3">
                                    {
                                        btnLoading ?
                                        <button className="btn btn-outline-primary" type="button"><T>Loading...</T></button> :
                                        <button className="btn btn-outline-primary" id="loadMoreProducts" type="button" onClick={loadMore}><T>Show More Products</T></button>
                                    }
                                </div> : <></>
                            }
                    </div>
                </div>
                }
            </section>
        </>
    );
}

export default Products;

