import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MultiRangeSlider from "multi-range-slider-react";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import T from "../../locale/translations-main";

function Sidebar({FilterOptions,update,toggle,content}){
    const [ refresh , setRefresh ] = useState(0)
    const [ keywordFilterOptions, setKeywordFilterOptions ] = useState("")
    const [ categoryFilterOptions , setCategoryFilterOptions ] = useState([])
    const [ genderFilterOptions , setGenderFilterOptions ] = useState([])
    const [ ageFilterOptions , setAgeFilterOptions ] = useState([])
    const [ brandFilterOptions , setBrandFilterOptions ] = useState([])
    const [ ratingFilterOptions , setRatingFilterOptions ] = useState([])
    const [ priceMaxAllowed, setPriceMaxAllowed ] = useState(0)
    const [ priceMaxSet, setPriceMaxSet ] = useState(0)
    const [ priceMinAllowed, setPriceMinAllowed ] = useState(0)
    const [ priceMinSet, setPriceMinSet ] = useState(0)
    const [ activeMin, setActiveMin ] = useState(0)
    const [ activeMax, setActiveMax ] = useState(0)
    const [ activeRating, setActiveRating ] = useState(0)
    const [ loading, setLoading ] = useState(true)
    const [ search, setSearch] = useSearchParams();
    
    const [ showMoreBrands, setShowMoreBrands ] = useState(false)
    const [ showMoreCategory, setShowMoreCategory ] = useState(false)
    const history = useNavigate();



    useEffect(()=>{
        let parentCategories = FilterOptions.AllGender.filter((item) => item.Active).map((a)=> a.Id)
        setCategoryFilterOptions(parentCategories.length ? FilterOptions.AllCategories.map((item)=> ( {...item, Active: false, hidden: !parentCategories.includes(item.ParentId)} ) ) : FilterOptions.AllCategories.map((item)=> ( {...item, hidden: false} ) ) )
        setGenderFilterOptions(FilterOptions.AllGender)
        setAgeFilterOptions(FilterOptions.AllAge)
        setBrandFilterOptions(FilterOptions.AllBrands)
        setRatingFilterOptions(FilterOptions.Ratings)
        setPriceMaxAllowed(FilterOptions.PriceMaxAllowed)
        setPriceMaxSet(FilterOptions.PriceMaxAllowed)
        setPriceMinAllowed(FilterOptions.PriceMinAllowed)
        setPriceMinSet(FilterOptions.PriceMinAllowed)
        setActiveMin(FilterOptions.PriceMinAllowed)
        setActiveMax(FilterOptions.PriceMaxAllowed)
        setKeywordFilterOptions(FilterOptions.Keyword)
        setLoading(false)
    },[FilterOptions,search])


    useEffect(() => {
        if(refresh){
            updateActiveFilters();
            window.scrollTo({top: document.getElementById("productSort").offsetTop - 40 , left: 0, behavior: "smooth"})
        }
    }, [refresh]);
    const updateActiveFilters = () => {
        let parentCategories = genderFilterOptions.filter((i)=> i.Active).map((i)=> { return i.Id })
        const filters = {
            categoryFilterOptions: parentCategories.length ? categoryFilterOptions.filter((i)=> (i.Active && parentCategories.includes(i.ParentId)) ).map((i)=> { return i.Id }) : categoryFilterOptions.filter((i)=> i.Active).map((i)=> { return i.Id }),
            genderFilterOptions: parentCategories,
            ageFilterOptions: ageFilterOptions.filter((i)=> i.Active).map((i)=> { return i.Id }),
            brandFilterOptions: brandFilterOptions.filter((i)=> i.Active).map((i)=> { return i.Id }),
            keywordFilterOptions: keywordFilterOptions && keywordFilterOptions.length ? keywordFilterOptions : "",
            priceMaxSet: activeMax,
            priceMinSet: activeMin,
            ...(activeRating !== 0 && {Rating: activeRating})
        }
        update(filters)
    }
    
    const addFilter = (e,f,type) => {
        if(type==="gender"){
            const active = genderFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: e.target.checked } : i
            })
            setGenderFilterOptions([...active])
            let parentCategories = active.filter((item) => item.Active).map((a)=> a.Id)
            setCategoryFilterOptions(parentCategories.length ? categoryFilterOptions.map((item)=> parentCategories.includes(item.ParentId) ? {...item, hidden: false} : {...item, Active: false, hidden: true} ) : categoryFilterOptions.map((item)=>( { ...item, hidden: false } )) )
        }
        if(type==="category"){
            const active = categoryFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: e.target.checked } : i
            })
            setCategoryFilterOptions([...active])
        }
        if(type==="brand"){
            const active = brandFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: e.target.checked } : i
            })
            setBrandFilterOptions([...active])
        }
        if(type==="age"){
            const active = ageFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: e.target.checked } : i
            })
            setAgeFilterOptions([...active])
        }
        setRefresh(refresh+1)
    }
    const removeSingleFilter = (f,type) => {
        if(type==="keyword"){
            setKeywordFilterOptions("")
        }
        if(type==="gender"){
            const active = genderFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: false } : {...i}
            })
            setGenderFilterOptions([...active])
        }
        if(type==="category"){
            const active = categoryFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: false } : {...i}
            })
            setCategoryFilterOptions([...active])
        }
        if(type==="brand"){
            const active = brandFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: false } : {...i}
            })
            setBrandFilterOptions([...active])
        }
        if(type==="age"){
            const active = ageFilterOptions.map((i)=>{
                return i.Id === f.Id ? { ...i, Active: false } : {...i}
            })
            setAgeFilterOptions([...active])
        }
        setRefresh(refresh+1)
    }
    const removeGroupFilter = (type=null) => {
        if(type==="keyword"){
            setKeywordFilterOptions("")
        }
        else if(type==="gender"){
            const g = genderFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setGenderFilterOptions([...g])
        }
        else if(type==="category"){
            const c = categoryFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setCategoryFilterOptions([...c])
        }
        else if(type==="brand"){
            const b = brandFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setBrandFilterOptions([...b])
        }
        else if(type==="age"){
            const a = ageFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setAgeFilterOptions([...a])
        }
        else if(type==="rating"){
            setActiveRating(0)
        }
        else if(type==="price"){
            setPriceMinSet(priceMinAllowed)
            setActiveMin(priceMinAllowed)
            setPriceMaxSet(priceMaxAllowed)
            setActiveMax(priceMaxAllowed)
        }
        else{
            setKeywordFilterOptions("")

            const g = genderFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setGenderFilterOptions([...g])
            const c = categoryFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setCategoryFilterOptions([...c])
            const b = brandFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setBrandFilterOptions([...b])
            const a = ageFilterOptions.map((i)=>{
                return { ...i, Active: false }
            })
            setAgeFilterOptions([...a])
            history('/products')
        }
        setRefresh(refresh+1)
    }
    const handleRange = (e) => {
        setPriceMinSet(parseInt(e.minValue));
        setPriceMaxSet(parseInt(e.maxValue));
    };
    const updateRating = (r) => {
        setActiveRating(r)
        setRefresh(refresh+1)
    }
    

    return ( loading ? 
        <aside className="product-sidebar">
            <Skeleton height="800px" style={{width: "100%"}} />
        </aside> :
        <aside className="product-sidebar">
            <div className="product-sidebar-overlay" onClick={()=>{toggle()}}></div>
            <button className="product-sidebar-close" onClick={()=>{toggle()}}><i className="ri-close-line"></i></button>
            
            <div className="product-sidebar-wrapper">
                <div className="sidebar-current-filters mb-4">
                    <div className="sidebar-section-header d-flex align-items-center justify-between">
                        <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Filters</T></h4>
                        { (categoryFilterOptions.filter((i)=>i.Active).length || (keywordFilterOptions && keywordFilterOptions.length) || genderFilterOptions.filter((i)=>i.Active).length || ageFilterOptions.filter((i)=>i.Active).length || brandFilterOptions.filter((i)=>i.Active).length) ? <button onClick={()=>{removeGroupFilter()}} className="link fs-14" type="button"><T>Clear All</T></button> : null }
                    </div>
                    <div className="current-filters">
                        {
                            (categoryFilterOptions.filter((i)=>i.Active).length || (keywordFilterOptions && keywordFilterOptions.length) || genderFilterOptions.filter((i)=>i.Active).length || ageFilterOptions.filter((i)=>i.Active).length || brandFilterOptions.filter((i)=>i.Active).length) ?
                            <></>: <p className="mb-0 mt-0 text-gray-400"><T>No filters selected</T></p>
                        }
                        {
                            (keywordFilterOptions && keywordFilterOptions.length) ? <span className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ removeSingleFilter(keywordFilterOptions,"keyword") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{keywordFilterOptions}</span> : <></>
                        }
                        {
                            genderFilterOptions.length ?
                            genderFilterOptions.map((filter,key) => {
                                return filter.Active ? <span key={key} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ removeSingleFilter(filter,"gender") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{filter.Value}</span> : null
                            }) : <></>
                        }
                        {
                            categoryFilterOptions.length ?
                            categoryFilterOptions.map((filter,key) => {
                                return filter.Active ? <span key={key} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ removeSingleFilter(filter,"category") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{filter.Value}</span> : null
                            }) : <></>
                        }
                        {
                            brandFilterOptions.length ?
                            brandFilterOptions.map((filter,key) => {
                                return filter.Active ? <span key={key} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ removeSingleFilter(filter,"brand") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{filter.Value}</span> : null
                            }) : <></>
                        }
                        {
                            ageFilterOptions.length ?
                            ageFilterOptions.map((filter,key) => {
                                return filter.Active ? <span key={key} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ removeSingleFilter(filter,"age") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{filter.Value}</span> : null
                            }) : <></>
                        }
                    </div>
                </div>
                {
                    genderFilterOptions.length ?
                    <div className="sidebar-gender mb-4">
                        <div className="sidebar-section-header d-flex align-items-center justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Gender</T></h4>
                            { genderFilterOptions.filter((i)=>i.Active).length ? <button onClick={()=>{removeGroupFilter("gender")}} className="link fs-14" type="button"><T>Clear All</T></button> : <></> }
                        </div>
                        <div className="gender-filters">
                            {
                                genderFilterOptions.map((g,index)=>{
                                    return <div className="mb-3" key={"gender"+index}>
                                        <input onChange={(e)=>{addFilter(e,g,"gender")}} type={"checkbox"} id={"gender"+index} className="input-checkbox" value={g.Value} checked = { g.Active } />
                                        <label htmlFor={"gender"+index} className="fs-14 fw-500 text-gray-500">{g.Value}</label>
                                    </div>
                                })
                            }
                        </div>
                    </div> : <></>
                }
                {
                    categoryFilterOptions.length ?
                    <div className="sidebar-category mb-4">
                        <div className="sidebar-section-header d-flex align-items-center justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Categories</T></h4>
                            { categoryFilterOptions.filter((i)=>i.Active && !i.hidden).length ? <button onClick={()=>{removeGroupFilter("category")}} className="link fs-14" type="button"><T>Clear All</T></button> : <></> }
                        </div>
                        <div className={"category-filters active"}>
                            {
                                showMoreCategory ?
                                categoryFilterOptions.filter((i)=>!i.hidden).map((cat,index)=>(
                                    <div className="mb-3" key={"category"+index}>
                                        <input onChange={(e)=>{addFilter(e,cat,"category")}} type={"checkbox"}  id={"category"+index} className="input-checkbox" value={cat.Value} checked = { cat.Active } />
                                        <label htmlFor={"category"+index} className="fs-14 fw-500 text-gray-500">{cat.Value}</label>
                                    </div>
                                )) :
                                categoryFilterOptions.slice(0,10).filter((i)=>!i.hidden).map((cat,index)=>(
                                    <div className="mb-3" key={"category"+index}>
                                        <input onChange={(e)=>{addFilter(e,cat,"category")}} type={"checkbox"}  id={"category"+index} className="input-checkbox" value={cat.Value} checked = { cat.Active } />
                                        <label htmlFor={"category"+index} className="fs-14 fw-500 text-gray-500">{cat.Value}</label>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            categoryFilterOptions.filter((i)=>!i.hidden).length > 10 ?
                            <span className="text-primary d-inline-block mt-2 cursor-pointer fs-14"  onClick={()=>{setShowMoreCategory(!showMoreCategory)}}>{ showMoreCategory ? <T>Show Less</T> : <T>Show More</T>}</span> : <></>
                        }
                    </div> : <></>
                }
                {
                    brandFilterOptions.length ?
                    <div className="sidebar-brand mb-4">
                        <div className="sidebar-section-header d-flex align-items-center justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Brands</T></h4>
                            { brandFilterOptions.filter((i)=>i.Active).length ? <button onClick={()=>{removeGroupFilter("brand")}} className="link fs-14" type="button"><T>Clear All</T></button> : <></> }
                        </div>
                        <div className={"brand-filters active"}>
                            {
                                showMoreBrands ?
                                brandFilterOptions.map((b,index)=>(
                                    <div className="mb-3" key={"brand"+index}>
                                        <input onChange={(e)=>{addFilter(e,b,"brand")}} type={"checkbox"}  id={"brand"+index} className="input-checkbox" value={b.Value} checked = { b.Active } />
                                        <label htmlFor={"brand"+index} className="fs-14 fw-500 text-gray-500">{b.Value}</label>
                                    </div>
                                )) :
                                brandFilterOptions.slice(0,10).map((b,index)=>(
                                    <div className="mb-3" key={"brand"+index}>
                                        <input onChange={(e)=>{addFilter(e,b,"brand")}} type={"checkbox"}  id={"brand"+index} className="input-checkbox" value={b.Value} checked = { b.Active } />
                                        <label htmlFor={"brand"+index} className="fs-14 fw-500 text-gray-500">{b.Value}</label>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            brandFilterOptions.length > 10 ?
                            <span className="text-primary d-inline-block mt-2 cursor-pointer fs-14" onClick={()=>{setShowMoreBrands(!showMoreBrands)}}>{ showMoreBrands ? <T>Show Less</T> : <T>Show More</T>}</span> : <></>
                        }
                    </div> : <></>
                }
                <div className="sidebar-price mb-5">
                    <div className="sidebar-section-header d-flex align-items-center justify-between">
                        <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Price</T></h4>
                        { activeMin !== priceMinAllowed || activeMax !== priceMaxAllowed ? <button onClick={()=>{removeGroupFilter("price")}} className="link fs-14" type="button"><T>Clear All</T></button> : <></> }
                    </div>
                    <div className="filter-range d-flex align-items-center justify-between mb-4 LTR text-left">
                        <div className="">
                            <label htmlFor="rangeMin" className="fs-14 fw-400 text-gray-500"><T>Min</T></label>
                            <input className="w-100" id="rangeMin" placeholder="Min Amount" type={"text"} min={priceMinAllowed} max={priceMaxAllowed-1} value={"SAR " +priceMinSet} readOnly={true}/>
                        </div>
                        <div className="">
                            <label htmlFor="rangeMax" className="fs-14 fw-400 text-gray-500"><T>Max</T></label>
                            <input className="w-100" id="rangeMax" placeholder="Max Amount" type={"text"} min={priceMinAllowed+1} max={priceMaxAllowed} value={"SAR " +priceMaxSet} readOnly={true}/>
                        </div>
                    </div>
                    <div className="price-filter mb-5 LTR text-left">
                        <MultiRangeSlider
                            min={priceMinAllowed}
                            max={priceMaxAllowed}
                            step={1}
                            ruler={true}
                            label={true}
                            preventWheel={false}
                            minValue={priceMinSet}
                            maxValue={priceMaxSet}
                            labels={["SAR","SAR"]}
                            onInput={(e) => {
                                handleRange(e);
                            }}
                        />
                    </div>
                    <button className="btn btn-outline-secondary w-100 justify-center" type="button" onClick={()=>{ setActiveMin(priceMinSet); setActiveMax(priceMaxSet); setRefresh(refresh+1); }}><T>Apply</T></button>
                </div>
                {
                    ageFilterOptions.length ?
                    <div className="sidebar-age mb-4">
                        <div className="sidebar-section-header d-flex align-items-center justify-between">
                            <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Age</T></h4>
                            { ageFilterOptions.filter((i)=>i.Active).length ? <button onClick={()=>{removeGroupFilter("age");}} className="link fs-14" type="button"><T>Clear All</T></button> : <></> }
                        </div>
                        <div className="age-filters">
                            {
                                ageFilterOptions.map((age,index)=>(
                                    <div className="mb-3" key={"age"+index}>
                                        <input onChange={(e)=>{addFilter(e,age,"age")}} type={"checkbox"}  id={"age"+index} className="input-checkbox" value={age.Value} checked = { age.Active } />
                                        <label htmlFor={"age"+index} className="fs-14 fw-500 text-gray-500"><span className="LTR">{age.Value}</span></label>
                                    </div>
                                ))
                            }
                        </div>
                    </div> : <></>
                }
                
                <div className="sidebar-rating mb-4">
                    <div className="sidebar-section-header d-flex align-items-center justify-between">
                        <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>By Rating</T></h4>
                        { activeRating > 0 ? <button onClick={()=>{removeGroupFilter("rating")}} className="link fs-14" type="button"><T>Clear All</T></button> : <></> }
                    </div>
                    <div className="rating-filters">
                        <button className={activeRating === ratingFilterOptions[4].Value ? "active" : ""} onClick={()=>{activeRating !== ratingFilterOptions[4].Value ? updateRating(ratingFilterOptions[4].Value) : console.log()}}><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-fill"></i></button>
                        <button className={activeRating === ratingFilterOptions[3].Value ? "active" : ""} onClick={()=>{activeRating !== ratingFilterOptions[3].Value ? updateRating(ratingFilterOptions[3].Value) : console.log()}}><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-line"></i><T>& Up</T></button>
                        <button className={activeRating === ratingFilterOptions[2].Value ? "active" : ""} onClick={()=>{activeRating !== ratingFilterOptions[2].Value ? updateRating(ratingFilterOptions[2].Value) : console.log()}}><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-line"></i><i className="ri-star-line"></i><T>& Up</T></button>
                        <button className={activeRating === ratingFilterOptions[1].Value ? "active" : ""} onClick={()=>{activeRating !== ratingFilterOptions[1].Value ? updateRating(ratingFilterOptions[1].Value) : console.log()}}><i className="ri-star-fill"></i><i className="ri-star-fill"></i><i className="ri-star-line"></i><i className="ri-star-line"></i><i className="ri-star-line"></i><T>& Up</T></button>
                        <button className={activeRating === ratingFilterOptions[0].Value ? "active" : ""} onClick={()=>{activeRating !== ratingFilterOptions[0].Value ? updateRating(ratingFilterOptions[0].Value) : console.log()}}><i className="ri-star-fill"></i><i className="ri-star-line"></i><i className="ri-star-line"></i><i className="ri-star-line"></i><i className="ri-star-line"></i><T>& Up</T></button>
                    </div>
                </div>
            </div>
        </aside>
    )
}
export default Sidebar;