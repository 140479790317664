import React, { useState,useEffect } from "react";
import Skeleton from 'react-loading-skeleton'
import { Link } from "react-router-dom";
import '../../assets/css/--home.css'
import { analyticsTrackEvent } from "../../components/Analytics";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

function HomeBanner ({permissions,banners,bannerLoading}){
    const [ bannerData , setBannerData] = useState([])
    const [ bannerSlideData , setBannerSlideData] = useState(null)
    const [loading , setLoading] = useState(true)
    
    useEffect(()=>{
        if(bannerLoading === false){
            setBannerData(banners.home);
            setBannerSlideData(banners.homeSlides);
            setLoading(false);
        }
    },[banners,bannerLoading])

    const isExternalLink = (url) => {
        const tmp = document.createElement('a');
        tmp.href = url;
        return tmp.host !== window.location.host;
    };
    
    return(
    <>
    {
        loading ?
          <section id="home_banner_section">
                <div className="home-banner">
                    <div className="top-banner">
                        <div className="top-loading-banner">
                            <Skeleton  className="banner-image"/>
                        </div>
                        <div className="top-loading-banner">
                            <Skeleton  className="banner-image"/>
                        </div>
                    </div>
                    <div className="bottom-banner">
                        <div className="bottom-loading-banner">
                            <Skeleton className="banner-image" />
                        </div>
                    </div>
                </div>
            </section> :
            <section id="home_banner_section">
                <div className="home-banner">
                    <div className="top-banner">
                        {
                            isExternalLink(bannerData[0].HyperLink) ?
                            <div onClick={()=>{analyticsTrackEvent("Home Banner Clicked", { screen_name: "Home", position: 2 }, permissions.tracking ); window.open(bannerData[0].HyperLink, '_blank').focus() }} className="right-banner-top banner-image cursor-pointer">
                                <div className="right-banner-top-content">
                                    <img src={bannerData[0].Image} alt="Clicktoys Banner"/>
                                </div>
                            </div> : 
                            <Link to={bannerData[0].HyperLink} onClick={()=>{analyticsTrackEvent("Home Banner Clicked", { screen_name: "Home", position: 2 }, permissions.tracking ); }} className="right-banner-top banner-image cursor-pointer">
                                <div className="right-banner-top-content">
                                    <img src={bannerData[0].Image} alt="Clicktoys Banner"/>
                                </div>
                            </Link>
                        }
                        {
                            isExternalLink(bannerData[1].HyperLink) ?
                            <div onClick={()=>{analyticsTrackEvent("Home Banner Clicked", { screen_name: "Home", position: 3 }, permissions.tracking ); window.open(bannerData[1].HyperLink, '_blank').focus() }} className="right-banner-bottom banner-image cursor-pointer">
                                <div className="right-banner-top-content">
                                    <img src={bannerData[1].Image} alt="Clicktoys Banner"/>
                                </div>
                            </div> : 
                            <Link to={bannerData[1].HyperLink} onClick={()=>{analyticsTrackEvent("Home Banner Clicked", { screen_name: "Home", position: 3 }, permissions.tracking );}} className="right-banner-bottom banner-image cursor-pointer">
                                <div className="right-banner-top-content">
                                    <img src={bannerData[1].Image} alt="Clicktoys Banner"/>
                                </div>
                            </Link>
                        }
                    </div>
                    <div className="bottom-banner">
                        <Swiper pagination={{ clickable: true }} autoplay={{ delay: 5000, disableOnInteraction: false, }} modules={[Pagination, Autoplay]} className="left-banner-wrapper">
                        {
                            bannerSlideData.map((item,index)=>{
                                return(
                                    <SwiperSlide key={index}>
                                        {
                                            isExternalLink(item.HyperLink) ?
                                            <div onClick={()=>{analyticsTrackEvent("Home Banner Clicked", { screen_name: "Home", position: 1 }, permissions.tracking ); window.open(item.HyperLink, '_blank').focus() }}  className="banner-image cursor-pointer">
                                                <div className="banner-content">
                                                    <img src={item.Image} alt="Clicktoys Banner"/>
                                                </div> 
                                            </div> :
                                            <Link to={item.HyperLink} onClick={()=>{analyticsTrackEvent("Home Banner Clicked", { screen_name: "Home", position: 1 }, permissions.tracking ); }}  className="banner-image cursor-pointer">
                                                <div className="banner-content">
                                                    <img src={item.Image} alt="Clicktoys Banner"/>
                                                </div> 
                                            </Link>
                                        }
                                    </SwiperSlide>
                                )
                            })
                        }
                        </Swiper>
                    </div>
                </div>
            </section>
}
            </>
        
            
    )
}

export default HomeBanner;